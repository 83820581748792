import consumer from './consumer'

const exportChannel = (referenceHash) =>
  consumer.subscriptions.create(
    { channel: 'ExportChannel', reference_hash: referenceHash },
    {
      received(data) {
        $('div[role="exporting"]').addClass('d-none')
        $('div[role="exported"]').removeClass('d-none')
        $('a[role="exported-file"]').attr('href', data.filepath)
      }
    }
  )

window.exportChannel = exportChannel
