// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('lodash')
const feather = require('feather-icons')

import 'channels'

import Swal from 'sweetalert2'
import Turbolinks from 'turbolinks'
import * as ActiveStorage from '@rails/activestorage'
import { scrollTop } from './src/utils'

Turbolinks.start()
ActiveStorage.start()

$(document).on('turbolinks:load', () => {
  window.feather = feather
  window.Swal = Swal
  window.scrollTop = scrollTop

  $('div[role="pagination"] a').attr('data-remote', 'true')

  feather.replace()
})
